<template>
  <div class="model-modal" v-if="visible" @click.self="handleClose">
    <div class="modal-content">
      <div class="logo-container">
        <LogoItem :logoOnly="true" :brightness="90" />
      </div>

      <div class="action-buttons">
        <div
          v-for="btn in actionButtons"
          :key="btn.name"
          class="action-btn"
          :title="btn.tooltip"
          @click="handleAction(btn.name)"
          v-html="btn.icon"
        ></div>
      </div>

      <div class="model-viewer">
        <canvas ref="canvas" class="model-canvas" v-show="visible"></canvas>

        <!-- 加载状态 -->
        <div class="loading-status" v-if="loadingStatus !== 'Loading complete'">
          {{ loadingStatus }}
        </div>

        <!-- 缩略图 -->
        <div class="thumbnail-container" v-if="thumbnailUrl">
          <img
            :src="thumbnailUrl"
            alt="Model thumbnail"
            class="thumbnail-image"
          />
        </div>

        <!-- 操作提示 -->
        <div class="controls-hint">
          <span>Left: Rotate</span>
          <span>Wheel: Zoom</span>
          <span>Right: Pan</span>
        </div>

        <!-- 光照控制面板 -->
        <div class="lighting-controls-wrapper">
          <!-- 触发按钮 -->
          <button
            class="lighting-toggle"
            @click="showLightingControls = !showLightingControls"
            :class="{ active: showLightingControls }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <circle cx="12" cy="12" r="5" />
              <path
                d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42"
              />
            </svg>
          </button>

          <!-- 控制面板 -->
          <div class="lighting-controls" v-show="showLightingControls">
            <div class="control-group">
              <label>Direction</label>
              <div class="direction-control">
                <div
                  class="direction-circle"
                  @mousedown="startDirectionDrag"
                  @mousemove="handleDirectionDrag"
                  @mouseup="stopDirectionDrag"
                  @mouseleave="stopDirectionDrag"
                >
                  <div
                    class="direction-pointer"
                    :style="{
                      left: `${50 + lightingSettings.direction.x}%`,
                      top: `${50 + lightingSettings.direction.y}%`,
                    }"
                  ></div>
                </div>
              </div>
            </div>

            <div class="control-group">
              <label>Depth</label>
              <input
                type="range"
                v-model="lightingSettings.depth"
                min="0"
                max="10"
                step="0.1"
              />
            </div>

            <div class="control-group">
              <label>Background</label>
              <div class="background-options">
                <button
                  class="bg-option"
                  :class="{
                    active: lightingSettings.background === 'transparent',
                  }"
                  @click="lightingSettings.background = 'transparent'"
                >
                  Transparent
                </button>
                <button
                  class="bg-option"
                  :class="{ active: lightingSettings.background === 'color' }"
                  @click="lightingSettings.background = 'color'"
                >
                  Color
                </button>
              </div>

              <!-- 添加颜色选择器 -->
              <div
                class="color-picker"
                v-if="lightingSettings.background === 'color'"
              >
                <hex-color-picker
                  :color="lightingSettings.backgroundColor"
                  @color-changed="handleColorChange"
                  class="custom-color-picker"
                ></hex-color-picker>
                <span class="color-value">{{
                  lightingSettings.backgroundColor
                }}</span>
              </div>
            </div>

            <div class="control-group">
              <label>Highlights</label>
              <div class="highlights-controls">
                <div class="highlight-control">
                  <label>Power</label>
                  <input
                    type="range"
                    v-model="lightingSettings.highlights.power"
                    min="0"
                    max="100"
                  />
                </div>
                <div class="highlight-control">
                  <label>Intensity</label>
                  <input
                    type="range"
                    v-model="lightingSettings.highlights.intensity"
                    min="0"
                    max="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 在 display-mode-controls 之前添加显示模式控制 -->
        <div class="display-mode-controls">
          <button
            v-for="mode in displayModes"
            :key="mode.value"
            :class="['mode-btn', { active: currentDisplayMode === mode.value }]"
            @click="changeDisplayMode(mode.value)"
          >
            {{ mode.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  onUnmounted,
  watch,
  nextTick,
} from "vue";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { VertexNormalsHelper } from "three/examples/jsm/helpers/VertexNormalsHelper";
import "vanilla-colorful";
import LogoItem from "./LogoItem.vue";

const actionButtons = [
  {
    name: "copy",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
            <g stroke="currentColor" stroke-linejoin="round" clip-path="url(#CloseAll_svg__a)">
              <path d="M4.445 4.504V2.508a1.5 1.5 0 0 1 1.5-1.5h6.55a1.5 1.5 0 0 1 1.5 1.5v6.55a1.5 1.5 0 0 1-1.5 1.5h-1.957"></path>
              <rect width="9.585" height="9.585" x="0.91" y="4.505" rx="1.5"></rect>
            </g>
          </svg>`,
    tooltip: "Copy to clipboard",
  },
  {
    name: "share",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none" class="text-white">
      <g stroke="currentColor" stroke-linecap="round" clip-path="url(#SaveAs_svg__a)">
        <path d="M9.577 11.715h2.076a2.796 2.796 0 1 0-.455-5.555l.001-.082a3.699 3.699 0 1 0-7.397.082 2.796 2.796 0 1 0-.455 5.555h2.076"></path>
        <path stroke-linejoin="round" d="M7.5 12.533v-5.98M9.973 9.03 7.495 6.553 5.018 9.03"></path>
      </g><defs><clipPath id="SaveAs_svg__a"><path fill="#fff" d="M0 0h15v15H0z"></path></clipPath></defs>
    </svg>`,
    tooltip: "Share",
  },
  {
    name: "download",
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" 
              d="M7.5 1.488v9.99M2.488 6.467l5.014 5.012 5.012-5.012M13.48 13.512H1.516">
            </path>
          </svg>`,
    tooltip: "Download",
  },
];

// 添加显示模式的常量定义
const displayModes = [
  { name: "Normal", value: "normal" },
  { name: "Wireframe", value: "wireframe" },
  { name: "Vertices", value: "vertices" },
  { name: "Untextured", value: "untextured" },
  { name: "Normals", value: "normals" },
];

export default defineComponent({
  name: "ModelViewer",
  components: {
    LogoItem,
  },
  props: {
    modelUrl: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    thumbnailUrl: {
      type: String,
      default: "",
    },
  },
  emits: ["close", "download"],
  setup(props, { emit }) {
    const canvas = ref<HTMLCanvasElement | null>(null);
    const loadingStatus = ref("Not started");

    let scene: THREE.Scene | null = null;
    let camera: THREE.PerspectiveCamera | null = null;
    let renderer: THREE.WebGLRenderer | null = null;
    let controls: OrbitControls | null = null;
    let model: THREE.Object3D | null = null;
    let lights: {
      ambient: THREE.AmbientLight | null;
      directional: THREE.DirectionalLight | null;
    } = {
      ambient: null,
      directional: null,
    };
    let animationFrameId: number;

    const showLightingControls = ref(false);
    const lightingSettings = ref({
      direction: { x: 35, y: -35 },
      depth: 5,
      background: "color",
      backgroundColor: "#a5adde",
      highlights: {
        power: 75,
        intensity: 100,
      },
    });

    const currentDisplayMode = ref(displayModes[0].value);
    let normalHelper: VertexNormalsHelper | null = null;

    const changeDisplayMode = (mode: string) => {
      if (!model || !scene) return;

      // 清除之前的法线辅助对象
      if (normalHelper) {
        scene.remove(normalHelper);
        normalHelper = null;
      }

      currentDisplayMode.value = mode;

      model.traverse((object: THREE.Object3D) => {
        if (object instanceof THREE.Mesh) {
          switch (mode) {
            case "normal":
              if (object.userData.originalMaterial) {
                object.material = object.userData.originalMaterial;
              }
              break;

            case "wireframe":
              if (!object.userData.originalMaterial) {
                object.userData.originalMaterial = object.material;
              }
              object.material = new THREE.MeshBasicMaterial({
                wireframe: true,
                color: 0xffffff,
              });
              break;

            case "vertices":
              if (!object.userData.originalMaterial) {
                object.userData.originalMaterial = object.material;
              }
              object.material = new THREE.PointsMaterial({
                size: 2,
                color: 0xffffff,
              });
              break;

            case "untextured":
              if (!object.userData.originalMaterial) {
                object.userData.originalMaterial = object.material;
              }
              object.material = new THREE.MeshStandardMaterial({
                color: 0xcccccc,
                roughness: 0.7,
                metalness: 0.1,
              });
              break;

            case "normals":
              if (!object.userData.originalMaterial) {
                object.userData.originalMaterial = object.material;
              }
              // 创建法线辅助对象
              if (scene) {
                normalHelper = new VertexNormalsHelper(
                  object,
                  0.2, // 法线长度
                  0x00ff00 // 法线颜色
                );
                scene.add(normalHelper);
              }
              break;
          }
        }
      });
    };

    // 方向控制相关的状态
    const isDragging = ref(false);
    const startDirectionDrag = (event: MouseEvent) => {
      isDragging.value = true;
      handleDirectionDrag(event);
    };

    const handleDirectionDrag = (event: MouseEvent) => {
      if (!isDragging.value) return;

      const circle = event.currentTarget as HTMLElement;
      const rect = circle.getBoundingClientRect();

      // 计算相对位置 (-50 到 50)
      const x = ((event.clientX - rect.left) / rect.width) * 100 - 50;
      const y = ((event.clientY - rect.top) / rect.height) * 100 - 50;

      // 限制在圆形范围内
      const distance = Math.sqrt(x * x + y * y);
      const maxDistance = 50;

      if (distance > maxDistance) {
        const scale = maxDistance / distance;
        lightingSettings.value.direction.x = x * scale;
        lightingSettings.value.direction.y = y * scale;
      } else {
        lightingSettings.value.direction.x = x;
        lightingSettings.value.direction.y = y;
      }

      updateLighting();
    };

    const stopDirectionDrag = () => {
      isDragging.value = false;
    };

    // 更新光照设置
    const updateLighting = () => {
      if (!lights.directional || !scene) return;

      // 更新方向光位置
      const { x, y } = lightingSettings.value.direction;
      const depth = lightingSettings.value.depth;
      lights.directional.position.set(
        (x / 35) * 5, // 将 ±35 映射到 ±5
        -(y / 35) * 5, // Y轴反转以匹配UI方向
        depth
      );

      // 更新光照强度
      const { power, intensity } = lightingSettings.value.highlights;
      if (lights.ambient) {
        lights.ambient.intensity = intensity / 50; // 将 0-100 映射到 0-2
      }
      lights.directional.intensity = power / 50;

      // 更新背景
      if (scene) {
        if (lightingSettings.value.background === "transparent") {
          scene.background = null;
          if (renderer) {
            renderer.setClearColor(0x000000, 0);
          }
        } else {
          scene.background = new THREE.Color(
            lightingSettings.value.backgroundColor
          );
        }
      }
    };

    // 监听光照设置的变化
    watch(
      () => lightingSettings.value,
      () => {
        updateLighting();
      },
      { deep: true }
    );

    const initScene = () => {
      try {
        // 更严格的清理逻辑
        if (scene) {
          scene.traverse((object: THREE.Object3D) => {
            if (object instanceof THREE.Mesh) {
              object.geometry?.dispose();
              if (object.material instanceof THREE.Material) {
                object.material.dispose();
              } else if (Array.isArray(object.material)) {
                object.material.forEach((material: THREE.Material) =>
                  material.dispose()
                );
              }
            }
          });
          scene.clear();
          renderer?.dispose();
          controls?.dispose();
          scene = null;
        }

        scene = new THREE.Scene();
        scene.background = new THREE.Color(
          lightingSettings.value.backgroundColor
        );

        const container = canvas.value?.parentElement;
        const width = container?.clientWidth || window.innerWidth;
        const height = container?.clientHeight || window.innerHeight;

        camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
        camera.position.z = 5;

        renderer = new THREE.WebGLRenderer({
          canvas: canvas.value as HTMLCanvasElement,
          antialias: true,
          alpha: true,
        });
        renderer.setSize(width, height);
        renderer.setPixelRatio(window.devicePixelRatio);

        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.05;
        controls.screenSpacePanning = true;

        // Add lights with values matching the default settings
        lights = {
          ambient: new THREE.AmbientLight(
            0xffffff,
            lightingSettings.value.highlights.intensity / 50
          ),
          directional: new THREE.DirectionalLight(
            0xffffff,
            lightingSettings.value.highlights.power / 50
          ),
        };

        if (lights.directional && scene) {
          // 设置初始位置以匹配默认的 direction 设置
          lights.directional.position.set(
            (lightingSettings.value.direction.x / 35) * 5,
            -(lightingSettings.value.direction.y / 35) * 5,
            lightingSettings.value.depth
          );
          if (lights.ambient) scene.add(lights.ambient);
          scene.add(lights.directional);
        }

        // 设置初始背景
        if (scene) {
          if (lightingSettings.value.background === "transparent") {
            scene.background = null;
            if (renderer) {
              renderer.setClearColor(0x000000, 0);
            }
          } else {
            scene.background = new THREE.Color(
              lightingSettings.value.backgroundColor
            );
          }
        }

        loadModel();
        animate();
      } catch (e) {
        loadingStatus.value = `Initialization error: ${e}`;
        console.error("Scene initialization error:", e);
      }
    };

    const loadModel = () => {
      try {
        loadingStatus.value = "Loading...";
        console.log("Starting to load model from:", props.modelUrl);

        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/draco/");
        console.log("DracoLoader initialized");

        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader);

        if (!props.modelUrl) {
          throw new Error("No model URL provided");
        }

        loader.load(
          props.modelUrl,
          (gltf) => {
            if (!scene || !camera || !controls) return;

            console.log("Model loaded successfully:", gltf);
            model = gltf.scene;

            // 保存原始材质
            model.traverse((object: THREE.Object3D) => {
              if (object instanceof THREE.Mesh) {
                object.userData.originalMaterial = object.material;
              }
            });

            scene.add(model);

            const box = new THREE.Box3().setFromObject(model);
            const center = box.getCenter(new THREE.Vector3());
            const size = box.getSize(new THREE.Vector3());

            model.position.x = -center.x;
            model.position.y = -center.y;
            model.position.z = -center.z;

            const maxDim = Math.max(size.x, size.y, size.z);
            camera.position.z = maxDim * 2;

            controls.target.set(0, 0, 0);
            controls.update();

            loadingStatus.value = "Loading complete";
          },
          (progress) => {
            const percent = (progress.loaded / progress.total) * 100;
            loadingStatus.value = `Loading... ${percent.toFixed(2)}%`;
            console.log(`Loading progress: ${percent.toFixed(2)}%`);
          },
          (error: unknown) => {
            console.error("Model loading error:", error);
            // loadingStatus.value = `Loading error: ${error.message}`;
          }
        );
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : String(error);
        loadingStatus.value = `Error: ${errorMessage}`;
        console.error("Error in loadModel:", error);
      }
    };

    const handleClose = () => {
      if (normalHelper && scene) {
        scene.remove(normalHelper);
        normalHelper = null;
      }
      // 清理场景
      if (scene) {
        scene.clear();
        renderer?.dispose();
        controls?.dispose();
        scene = null; // 重置场景
        model = null; // 重置模型
      }
      emit("close");
    };

    const handleDownload = () => {
      emit("download", props.modelUrl);
    };

    const handleResize = () => {
      if (!canvas.value || !renderer || !camera) return;

      const container = canvas.value.parentElement;
      const width = container?.clientWidth || window.innerWidth;
      const height = container?.clientHeight || window.innerHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      if (controls && scene && camera && renderer) {
        controls.update();
        if (normalHelper) {
          normalHelper.update();
        }
        renderer.render(scene, camera);
      }
    };

    const handleAction = (action: string) => {
      switch (action) {
        case "copy":
          // 实现复制链接功能
          navigator.clipboard.writeText(window.location.href);
          break;
        case "share":
          // 实现分享功能
          if (navigator.share) {
            navigator.share({
              title: "Share Model",
              url: window.location.href,
            });
          }
          break;
        case "download":
          handleDownload();
          break;
      }
    };

    const handleColorChange = (event: CustomEvent) => {
      console.log("Color changed:", event);
      lightingSettings.value.backgroundColor = event.detail.value;
    };

    watch(
      [() => props.visible, () => props.modelUrl],
      ([newVisible, newModelUrl]) => {
        nextTick(() => {
          console.log("Watch triggered:", {
            newVisible,
            newModelUrl,
            canvas: canvas.value,
          });
          if (newVisible && canvas.value) {
            console.log("Initializing scene...");
            initScene();
          }
        });
      },
      { immediate: true }
    );

    onMounted(() => {
      console.log("Component mounted");

      // 给canvas一点时间来初始化
      nextTick(() => {
        console.log("nextTick - canvas value:", canvas.value);
        if (canvas.value) {
          if (props.visible) {
            console.log("Initializing scene in nextTick");
            initScene();
          } else {
            console.log("Component not visible yet");
          }
        } else {
          console.warn("Canvas not available after nextTick");
        }
      });

      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      console.log("onUnmounted");
      window.removeEventListener("resize", handleResize);
      cancelAnimationFrame(animationFrameId);
      controls?.dispose();
      renderer?.dispose();
      isDragging.value = false;
    });

    return {
      canvas,
      loadingStatus,
      handleClose,
      handleDownload,
      actionButtons,
      handleAction,
      showLightingControls,
      lightingSettings,
      startDirectionDrag,
      handleDirectionDrag,
      stopDirectionDrag,
      handleColorChange,
      displayModes,
      currentDisplayMode,
      changeDisplayMode,
    };
  },
});
</script>

<style scoped lang="scss">
.model-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    position: relative;
    width: 80vw;
    height: 80vh;
    background: #1a1a1a;
    border-radius: 20px;
    overflow: hidden;

    .logo-container {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 10;
      height: 32px; // 调整 logo 大小
    }
  }

  .action-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
    z-index: 10;

    .action-btn {
      width: 35px;
      height: 35px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        transform: translateY(-2px);
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.model-viewer {
  width: 100%;
  height: 100%;
  position: relative;

  .model-canvas {
    width: 100%;
    height: 100%;
  }

  .loading-status {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 15px 30px;
    border-radius: 8px;
    color: white;
    font-family: monospace;
  }

  .controls-hint {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    background: rgba(0, 0, 0, 0.5);
    padding: 8px 16px;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;

    span {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .lighting-controls-wrapper {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 10;

    .lighting-toggle {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background: rgba(255, 255, 255, 0.1);
      border: none;
      color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &.active {
        background: rgba(255, 255, 255, 0.3);
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .lighting-controls {
      position: absolute;
      bottom: 50px;
      right: 0;
      width: 280px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 12px;
      padding: 16px;
      color: white;

      .control-group {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        > label {
          display: block;
          margin-bottom: 8px;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.7);
        }
      }

      .direction-control {
        .direction-circle {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          border: 2px solid rgba(255, 255, 255, 0.3);
          position: relative;
          margin: 0 auto;

          .direction-pointer {
            width: 12px;
            height: 12px;
            background: white;
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }

      input[type="range"] {
        width: 100%;
        height: 4px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 2px;
        -webkit-appearance: none;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 16px;
          height: 16px;
          background: white;
          border-radius: 50%;
          cursor: pointer;
        }
      }

      .background-options {
        display: flex;
        gap: 8px;

        .bg-option {
          flex: 1;
          padding: 8px;
          border-radius: 6px;
          border: 1px solid rgba(255, 255, 255, 0.3);
          background: transparent;
          color: white;
          cursor: pointer;

          &.active {
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }

      .highlights-controls {
        .highlight-control {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }

          label {
            display: block;
            margin-bottom: 4px;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }
    }
  }

  .thumbnail-container {
    position: absolute;
    bottom: 80px; // 位于环境光控制上方
    left: 20px;
    z-index: 10;
    width: 120px;
    height: 120px;
    border-radius: 12px;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
      border-color: rgba(255, 255, 255, 0.2);
    }

    .thumbnail-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.color-picker {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);

  .custom-color-picker {
    width: 100% !important;
    height: 150px !important;

    // 自定义颜色选择器样式
    --pcr-color: var(--color);
    border-radius: 8px;
    overflow: hidden;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
    }
  }

  .color-value {
    font-size: 13px;
    font-family: "Monaco", "Menlo", monospace;
    color: rgba(255, 255, 255, 0.8);
    padding: 6px 10px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    text-transform: uppercase;
    text-align: center;
  }
}

.background-options {
  .bg-option {
    flex: 1;
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.8);
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
      transform: translateY(-1px);
    }

    &.active {
      background: rgba(255, 255, 255, 0.15);
      border-color: rgba(255, 255, 255, 0.4);
      color: white;
    }
  }
}

.display-mode-controls {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
  z-index: 10;

  .mode-btn {
    padding: 8px 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      transform: translateY(-2px);
    }

    &.active {
      background: rgba(255, 255, 255, 0.3);
      transform: translateY(-2px);
    }
  }
}
</style>
