import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, createCommentVNode as _createCommentVNode, renderList as _renderList, withModifiers as _withModifiers, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "action-buttons" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["upload-block", { 'is-dragging': _ctx.isDragging }]),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.triggerFileUpload && _ctx.triggerFileUpload(...args))),
    onDragover: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleDragOver && _ctx.handleDragOver(...args))),
    onDragleave: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args))),
    onDrop: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)))
  }, [
    _createElementVNode("input", {
      type: "file",
      ref: "fileInput",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args))),
      accept: "image/*",
      class: "file-input"
    }, null, 544),
    _createElementVNode("div", {
      class: _normalizeClass(["upload-content", { 'has-preview': _ctx.previewImages.length > 0 }])
    }, [
      (_ctx.previewImages.length === 0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"upload-icon\" data-v-dfa2259e><svg width=\"48\" height=\"48\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" data-v-dfa2259e><path d=\"M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z\" fill=\"currentColor\" data-v-dfa2259e></path></svg></div><div class=\"upload-text\" data-v-dfa2259e><div data-v-dfa2259e>Drag &amp; Drop image here</div><div data-v-dfa2259e>OR click to upload</div></div><div class=\"supported-formats\" data-v-dfa2259e> Supported Formats: JPG, JPEG, PNG, WEBP. Max Size: 20MB. </div>", 3))
          ], 64))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["preview-container", { loading: _ctx.isLoading }])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.previewImages, (preview, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "preview-image-wrapper"
              }, [
                _createElementVNode("img", {
                  src: preview,
                  class: "preview-image"
                }, null, 8, _hoisted_1),
                _createElementVNode("button", {
                  class: "remove-image",
                  onClick: _withModifiers(($event: any) => (_ctx.removeImage(index)), ["stop"])
                }, _cache[8] || (_cache[8] = [
                  _createElementVNode("svg", {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "16",
                    height: "16",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    stroke: "currentColor",
                    "stroke-width": "2",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }, [
                    _createElementVNode("line", {
                      x1: "18",
                      y1: "6",
                      x2: "6",
                      y2: "18"
                    }),
                    _createElementVNode("line", {
                      x1: "6",
                      y1: "6",
                      x2: "18",
                      y2: "18"
                    })
                  ], -1)
                ]), 8, _hoisted_2)
              ]))
            }), 128))
          ], 2))
    ], 2),
    _createVNode(_Transition, { name: "fade-slide" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_3, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "placeholder-btn" }, null, -1)),
          _createElementVNode("button", {
            class: "generate-btn",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleGenerateClick && _ctx.handleGenerateClick(...args))),
            disabled: _ctx.isLoading
          }, " Generate ", 8, _hoisted_4),
          _createElementVNode("button", {
            class: "settings-btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleSettingsClick && _ctx.handleSettingsClick(...args))),
            disabled: _ctx.isLoading
          }, _cache[9] || (_cache[9] = [
            _createElementVNode("i", { class: "fa-solid fa-sliders fa-fw" }, null, -1)
          ]), 8, _hoisted_5)
        ], 512), [
          [_vShow, _ctx.previewImages.length > 0]
        ])
      ]),
      _: 1
    })
  ], 34))
}