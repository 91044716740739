import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo_full.svg'
import _imports_1 from '@/assets/logo.png'


const _hoisted_1 = { class: "logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.logoOnly)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _imports_0,
          alt: "Crafto",
          style: _normalizeStyle({ filter: _ctx.brightness ? `brightness(${_ctx.brightness}%)` : '' })
        }, null, 4))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _imports_1,
          alt: "Crafto",
          style: _normalizeStyle({ filter: _ctx.brightness ? `brightness(${_ctx.brightness}%)` : '' })
        }, null, 4))
  ]))
}