import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import AppView from "../views/AppView.vue";
import AppView2 from "../views/AppView2.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: AppView2,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/apps",
    name: "apps",
    component: () =>
      import(/* webpackChunkName: "apps" */ "../views/AppView.vue"),
  },
  {
    path: "/apps2",
    name: "apps2",
    component: () =>
      import(/* webpackChunkName: "apps" */ "../views/AppView2.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () =>
      import(/* webpackChunkName: "test" */ "../views/TestView.vue"),
  },
  {
    path: "/test2",
    name: "test2",
    component: () =>
      import(/* webpackChunkName: "test2" */ "../views/TestView4Generate.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
