// 定义图片数据接口
export interface ImageItem {
  id: number;
  url: string;
  snapUrl?: string;
}

// 图片 0.png ~ 85.png
const mockImages: ImageItem[] = Array.from({ length: 86 }, (_, i) => ({
  id: i + 1,
  url: `/mock/images/${i}.png`,
}));

// 视频 1.mp4 ~ 368.mp4, skip 32
const mockVideos: ImageItem[] = Array.from({ length: 99 }, (_, i) => ({
  id: i + 1,
  url: i + 1 < 32 ? `/mock/videos/${i + 1}.mp4` : `/mock/videos/${i + 2}.mp4`,
  snapUrl:
    i + 1 < 32
      ? `/mock/videoSnapshots/${i + 1}.png`
      : `/mock/videoSnapshots/${i + 2}.png`,
}));

// 获取所有图片
export const getAllImages = (): Promise<ImageItem[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockVideos);
    }, 300);
  });
};

// 根据ID获取单个图片
export const getImageById = (id: number): Promise<ImageItem | undefined> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const image = mockImages.find((img) => img.id === id);
      resolve(image);
    }, 300);
  });
};
