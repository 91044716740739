<template>
  <div class="logo">
    <img
      src="@/assets/logo_full.svg"
      alt="Crafto"
      :style="{ filter: brightness ? `brightness(${brightness}%)` : '' }"
      v-if="!logoOnly"
    />
    <img
      src="@/assets/logo.png"
      alt="Crafto"
      :style="{ filter: brightness ? `brightness(${brightness}%)` : '' }"
      v-else
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogoItem",
  props: {
    logoOnly: {
      type: Boolean,
      default: false,
    },
    brightness: {
      type: Number,
      default: undefined,
    },
  },
});
</script>

<style scoped lang="scss">
.logo {
  height: 48px;
  display: flex;
  align-items: start;

  img {
    height: 100%;
  }
}
</style>
