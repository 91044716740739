<template>
  <div class="app-page" @wheel="handleWheel">
    <header class="header">
      <LogoItem />
      <div class="header-right">
        <button class="login-btn">Log in</button>
        <button class="menu-btn">
          <span class="menu-icon"></span>
        </button>
      </div>
    </header>

    <main class="main-content">
      <div class="grid-container">
        <div
          v-for="image in visibleImages"
          :key="`${image.id}-${image.x}-${image.y}`"
          class="grid-item"
          :style="{
            left: `${image.x}px`,
            top: `${image.y}px`,
            width: `${image.width}px`,
            height: `${image.width}px`,
            transform: `translateY(${-scrollOffset}px) scale(${
              isHovered(image) ? 1.4 : 1
            })`,
            opacity: isHovered(image) ? 1 : 0.6,
            zIndex: isHovered(image) ? 10 : 1,
          }"
          @mouseenter="handleItemHover(image, true)"
          @mouseleave="handleItemHover(image, false)"
        >
          <img
            :src="image.snapUrl"
            :alt="`Model ${image.id}`"
            class="grid-image"
          />
          <video
            :ref="(el) => setVideoRef(el, image)"
            loop
            muted
            class="grid-video"
            v-show="
              hoveredVideoId === image.id &&
              hoveredX === image.x &&
              hoveredY === image.y
            "
          ></video>
        </div>

        <transition name="fade">
          <div v-show="!isScrolled" class="center-form">
            <ImageUploader
              @generate="handleGenerate"
              @settings="handleSettings"
              :is-loading="isLoading"
            />
          </div>
        </transition>

        <ModelViewer
          :model-url="modelUrl"
          :visible="showModel"
          :thumbnail-url="thumbnailUrl"
          @close="closeViewer"
          @download="handleDownload"
        />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, onUnmounted, computed } from "vue";
import LogoItem from "@/components/LogoItem.vue";
import ImageUploader from "@/components/ImageUploader.vue";
import { getAllImages, ImageItem } from "@/mock/images";
import { processImageToTexture } from "@/api/model";
import ModelViewer from "@/components/ModelViewer.vue";

interface PositionedImage extends ImageItem {
  x: number;
  y: number;
  width: number;
}

export default defineComponent({
  name: "AppView",
  components: {
    LogoItem,
    ImageUploader,
    ModelViewer,
  },
  setup() {
    const images = ref<PositionedImage[]>([]);
    const COLUMNS = ref(0);
    const ROWS = ref(0);
    const loadedImages = ref<ImageItem[]>([]);
    const isLoading = ref(false);
    const modelUrl = ref("");
    const showModel = ref(false);
    const thumbnailUrl = ref("");
    const hoveredVideoId = ref<number | null>(null);
    const hoveredX = ref<number | null>(null);
    const hoveredY = ref<number | null>(null);
    const videoRefs = new Map<string, HTMLVideoElement>();
    const imageWidth = 200;
    const PAD = 80;
    let resizeTimeout: number | null = null;
    const isScrolled = ref(false);
    const scrollOffset = ref(0);
    const centerHeight = ref(0);
    // const scrollThreshold = 50;
    const hoveredItem = ref<PositionedImage | null>(null);

    const calculateGridDimensions = () => {
      const availableWidth = window.innerWidth;
      const availableHeight = window.innerHeight; // Subtract header height

      COLUMNS.value = Math.ceil(availableWidth / (imageWidth + PAD));
      ROWS.value = Math.ceil(availableHeight / (imageWidth + PAD));

      // Ensure minimum grid size
      COLUMNS.value = Math.max(COLUMNS.value, 4);
      ROWS.value = Math.max(ROWS.value, 4);
    };

    const calculateImagePositions = () => {
      const containerHeight =
        document.querySelector(".grid-container")?.clientHeight || 1000;
      const containerWidth =
        document.querySelector(".grid-container")?.clientWidth || 1000;
      const yOffset =
        (containerHeight - ROWS.value * (imageWidth + PAD) + PAD) / 2;
      const xOffset =
        containerWidth / 2 -
        Math.floor(COLUMNS.value / 2) * (imageWidth + PAD) -
        imageWidth / 2;

      // const centerStartCol = Math.floor(COLUMNS.value / 2) - 1;
      // const centerEndCol = Math.ceil(COLUMNS.value / 2) + 1;
      // const centerStartRow = Math.floor(ROWS.value / 2) - 1;
      // const centerEndRow = Math.ceil(ROWS.value / 2);

      images.value = [];

      for (let i = 0; i < COLUMNS.value; i++) {
        for (let j = 0; j < ROWS.value; j++) {
          // if (
          //   i >= centerStartCol &&
          //   i <= centerEndCol &&
          //   j >= centerStartRow &&
          //   j <= centerEndRow
          // ) {
          //   continue;
          // }

          const image =
            loadedImages.value[
              (i * ROWS.value + j) % loadedImages.value.length
            ];
          images.value.push({
            ...image,
            x: i * (imageWidth + PAD) + xOffset,
            y: j * (imageWidth + PAD) + yOffset,
            width: imageWidth,
          });
        }
      }

      if (isScrolled.value) {
        const extraRows = Math.ceil(loadedImages.value.length / COLUMNS.value);
        for (let i = 0; i < COLUMNS.value; i++) {
          for (let j = ROWS.value; j < ROWS.value + extraRows; j++) {
            const image =
              loadedImages.value[
                (i * extraRows + j + ROWS.value * COLUMNS.value) %
                  loadedImages.value.length
              ];
            images.value.push({
              ...image,
              x: i * (imageWidth + PAD) + xOffset,
              y: j * (imageWidth + PAD) + yOffset,
              width: imageWidth,
            });
          }
        }
      }
    };

    const handleResize = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(() => {
        calculateGridDimensions();
        calculateImagePositions();
      }, 150); // Adjust delay as needed
    };

    onMounted(async () => {
      loadedImages.value = await getAllImages();
      calculateGridDimensions();
      calculateImagePositions();
      window.addEventListener("resize", handleResize);

      const centerForm = document.querySelector(".center-form");
      if (centerForm) {
        centerHeight.value = centerForm.clientHeight;
      }
    });

    onUnmounted(() => {
      if (thumbnailUrl.value) {
        URL.revokeObjectURL(thumbnailUrl.value);
      }
      window.removeEventListener("resize", handleResize);
    });

    const handleGenerate = async (file: File) => {
      console.log("Generate with images:", file);
      isLoading.value = true;
      thumbnailUrl.value = URL.createObjectURL(file);
      const res = await processImageToTexture(file);
      console.log(res);
      isLoading.value = false;
      modelUrl.value = res;
      showModel.value = true;
    };

    const handleSettings = () => {
      console.log("Open settings");
    };

    const closeViewer = () => {
      showModel.value = false;
    };

    const handleDownload = (url: string) => {
      console.log("Downloading model from:", url);
      // Implement download logic here if needed
      window.open(url, "_blank");
    };

    const setVideoRef = (
      el: HTMLVideoElement | null,
      image: PositionedImage
    ) => {
      const key = `${image.id}-${image.x}-${image.y}`;
      if (el) {
        videoRefs.set(key, el);
      } else {
        videoRefs.delete(key);
      }
    };

    const handleMouseEnter = async (image: PositionedImage) => {
      hoveredVideoId.value = image.id;
      hoveredX.value = image.x;
      hoveredY.value = image.y;

      const key = `${image.id}-${image.x}-${image.y}`;
      const video = videoRefs.get(key);

      if (video && !video.src) {
        video.src = image.url;
        await video.load();
      }

      if (video) {
        video.currentTime = 0;
        video.play().catch((err) => {
          console.warn("Video autoplay failed:", err);
        });
      }
    };

    const handleMouseLeave = (image: PositionedImage) => {
      hoveredVideoId.value = null;
      hoveredX.value = null;
      hoveredY.value = null;

      const key = `${image.id}-${image.x}-${image.y}`;
      const video = videoRefs.get(key);
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    };

    const handleWheel = (event: WheelEvent) => {
      if (event.deltaY > 0 && !isScrolled.value) {
        isScrolled.value = true;
        scrollOffset.value = 0;
        calculateImagePositions();
      } else if (isScrolled.value) {
        scrollOffset.value = Math.max(
          0,
          Math.min(
            scrollOffset.value + event.deltaY,
            (images.value.length / COLUMNS.value) * (imageWidth + PAD) -
              window.innerHeight
          )
        );
        if (scrollOffset.value === 0) {
          isScrolled.value = false;
        }
      }
    };

    const visibleImages = computed(() => {
      if (!isScrolled.value) {
        const containerHeight =
          document.querySelector(".grid-container")?.clientHeight || 1000;
        const containerWidth =
          document.querySelector(".grid-container")?.clientWidth || 1000;
        const yOffset =
          (containerHeight - ROWS.value * (imageWidth + PAD) + PAD) / 2;
        const xOffset =
          containerWidth / 2 -
          Math.floor(COLUMNS.value / 2) * (imageWidth + PAD) -
          imageWidth / 2;

        const centerStartCol = Math.floor(COLUMNS.value / 2) - 1;
        const centerEndCol = Math.floor(COLUMNS.value / 2) + 1;
        const centerStartRow = Math.floor(ROWS.value / 2) - 1;
        const centerEndRow = Math.floor(ROWS.value / 2) + 1;

        return images.value.filter((img) => {
          const imgCol = Math.floor((img.x - xOffset) / (imageWidth + PAD));
          const imgRow = Math.floor((img.y - yOffset) / (imageWidth + PAD));

          return !(
            imgCol >= centerStartCol &&
            imgCol <= centerEndCol &&
            imgRow >= centerStartRow &&
            imgRow <= centerEndRow
          );
        });
      }

      return images.value;
    });

    const isHovered = (image: PositionedImage) => {
      return hoveredItem.value === image;
    };

    const handleItemHover = (image: PositionedImage, isEntering: boolean) => {
      hoveredItem.value = isEntering ? image : null;
      if (isEntering) {
        handleMouseEnter(image);
      } else {
        handleMouseLeave(image);
      }
    };

    return {
      images,
      handleGenerate,
      handleSettings,
      isLoading,
      modelUrl,
      showModel,
      closeViewer,
      handleDownload,
      handleMouseEnter,
      handleMouseLeave,
      thumbnailUrl,
      hoveredVideoId,
      setVideoRef,
      hoveredX,
      hoveredY,
      isScrolled,
      scrollOffset,
      visibleImages,
      handleWheel,
      isHovered,
      handleItemHover,
    };
  },
});
</script>

<style scoped lang="scss">
.app-page {
  min-height: 100vh;
  height: 100vh;
  background: #13120f;
  color: white;
  position: relative;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .header-right {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .login-btn {
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .menu-btn {
    background: transparent;
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .menu-icon {
      width: 24px;
      height: 2px;
      background: white;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 2px;
        background: white;
        left: 0;
      }

      &::before {
        top: -6px;
      }

      &::after {
        bottom: -6px;
      }
    }
  }
}

.main-content {
  // padding: 80px 2rem 2rem;
  overflow: hidden;
  height: 100vh;
}

.grid-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.grid-item {
  position: absolute;
  border-radius: 12px;
  overflow: hidden;
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.5s ease-in;
  will-change: transform, opacity;
  animation: fadeIn 0.3s ease-in forwards;

  .grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .grid-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.15s;
    pointer-events: none;

    &[src] {
      opacity: 1;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.grid-item {
  position: absolute;
  transition: transform 0.3s ease-out;
}

.center-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>
