interface FileData {
  path: string;
  url: string;
  orig_name: string;
  size: number | null;
  mime_type: string | null;
  is_stream?: boolean;
  meta: {
    _type: string;
  };
}

interface QueueResponse {
  msg: string;
  output?: {
    data: FileData[];
  };
  error?: string;
}

const BASE_URL = process.env.VUE_APP_API_BASE_URL;

const HEADERS = {
  "Accept-Language": "zh,en-US;q=0.9,en;q=0.8",
  DNT: "1",
  "User-Agent":
    "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.0.0.0 Safari/537.36",
  "Proxy-Connection": "keep-alive",
  Referer: `${BASE_URL}/`,
};

// 生成随机会话哈希
const generateHash = (length = 15): string => {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from(
    { length },
    () => chars[Math.floor(Math.random() * chars.length)]
  ).join("");
};

// 上传文件
async function uploadFile(file: File, uploadId: string): Promise<FileData[]> {
  const formData = new FormData();
  formData.append("files", file);

  const response = await fetch(
    `${BASE_URL}/gradio_api/upload?upload_id=${uploadId}`,
    {
      method: "POST",
      headers: {
        ...HEADERS,
        Accept: "*/*",
        Origin: BASE_URL,
      },
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error(`Upload failed with status: ${response.status}`);
  }

  const paths = await response.json();
  return paths.map((path: string) => ({
    path,
    url: `${BASE_URL}/gradio_api/file=${path}`,
    orig_name: file.name,
    size: file.size,
    mime_type: file.type,
    meta: { _type: "gradio.FileData" },
  }));
}

// 加入队列
async function joinQueue(
  fnIndex: number,
  data: FileData[] = [],
  sessionHash: string
) {
  const response = await fetch(`${BASE_URL}/gradio_api/queue/join`, {
    method: "POST",
    headers: {
      ...HEADERS,
      Accept: "*/*",
      Origin: BASE_URL,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data,
      event_data: null,
      fn_index: fnIndex,
      trigger_id: 5,
      session_hash: sessionHash,
    }),
  });

  if (!response.ok) {
    throw new Error(`Failed to join queue: ${response.status}`);
  }

  return response.json();
}

// 获取队列数据
async function getQueueData(sessionHash: string): Promise<QueueResponse> {
  return new Promise((resolve, reject) => {
    const eventSource = new EventSource(
      `${BASE_URL}/gradio_api/queue/data?session_hash=${sessionHash}`
    );

    const timeout = setTimeout(() => {
      eventSource.close();
      reject(new Error("Queue processing timeout"));
    }, 300000);

    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data) as QueueResponse;
        console.log("Queue event:", data.msg);

        switch (data.msg) {
          case "process_completed":
            clearTimeout(timeout);
            eventSource.close();
            resolve(data);
            break;
          case "process_failed":
            clearTimeout(timeout);
            eventSource.close();
            reject(new Error(data.error || "Unknown error"));
            break;
          case "process_starts":
            console.log("Process started...");
            break;
          case "process_generating":
            console.log("Processing...");
            break;
          default:
            console.log("Unknown message:", data);
        }
      } catch (error) {
        console.error("Error parsing event:", error);
      }
    };

    eventSource.onerror = (error) => {
      clearTimeout(timeout);
      eventSource.close();
      reject(new Error("EventSource failed: " + error));
    };
  });
}

// 主处理函数
export async function processImageToTexture(imageFile: File): Promise<string> {
  const sessionHash = generateHash();
  const uploadId = generateHash();

  try {
    // 1. 上传文件
    const uploadedFiles = await uploadFile(imageFile, uploadId);
    const imageData = uploadedFiles[0];

    console.log("Uploaded file:", imageData);

    // 2. 处理图片（步骤1-4）
    const meshPaths: string[] = [];
    for (let fnIndex = 1; fnIndex <= 4; fnIndex++) {
      console.log(`Starting step ${fnIndex}...`);

      // 只在步骤1和3需要图片数据
      const data = fnIndex === 1 || fnIndex === 3 ? [imageData] : [];

      const queueResponse = await joinQueue(fnIndex, data, sessionHash);
      console.log(`Queue joined for step ${fnIndex}:`, queueResponse);

      const result = await getQueueData(sessionHash);
      console.log(`Step ${fnIndex} result:`, result);

      if (result.output?.data?.[0]?.path) {
        meshPaths.push(result.output.data[0].path);
        console.log(
          `Step ${fnIndex} completed, path:`,
          meshPaths[meshPaths.length - 1]
        );
      }
    }

    if (!meshPaths.length) {
      throw new Error("No mesh file generated");
    }

    console.log("meshPaths", meshPaths);

    // 3. 准备纹理处理数据
    const meshData: FileData = {
      path: meshPaths[meshPaths.length - 1],
      url: `${BASE_URL}/gradio_api/file=${meshPaths[meshPaths.length - 1]}`,
      orig_name: "output_mesh.glb",
      size: null,
      mime_type: null,
      is_stream: false,
      meta: { _type: "gradio.FileData" },
    };

    // 4. 处理纹理（步骤5-8）
    const texturePaths: string[] = [];
    for (let fnIndex = 5; fnIndex <= 8; fnIndex++) {
      console.log(`Starting texture step ${fnIndex}...`);

      const data = fnIndex === 5 || fnIndex === 7 ? [imageData, meshData] : [];

      const queueResponse = await joinQueue(fnIndex, data, sessionHash);
      console.log(`Queue joined for texture step ${fnIndex}:`, queueResponse);

      const result = await getQueueData(sessionHash);
      console.log(`Texture step ${fnIndex} result:`, result);

      if (result.output?.data?.[0]?.path) {
        texturePaths.push(result.output.data[0].path);
        console.log(
          `Texture step ${fnIndex} completed, path:`,
          texturePaths[texturePaths.length - 1]
        );
      }
    }

    if (!texturePaths.length) {
      throw new Error("No texture file generated");
    }

    // 返回完整的 URL
    const finalPath = texturePaths[texturePaths.length - 1];
    return `${BASE_URL}/gradio_api/file=${finalPath}`;
  } catch (error) {
    console.error("Error in processImageToTexture:", error);
    throw error;
  }
}
